.paragraph {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .paragraph {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 992px) {
  .paragraph {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .paragraph {
    margin-bottom: 50px;
  }
}

.paragraph:last-child {
  margin-bottom: 0;
}

.paragraph .text-formatted:last-child h2:last-child, .paragraph .text-formatted:last-child h3:last-child, .paragraph .text-formatted:last-child h4:last-child {
  margin-bottom: 0;
}

.paragraph.width-content-wide > .paragraph-inner {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .paragraph.width-content-wide > .paragraph-inner {
    max-width: 750px;
  }
}

@media screen and (min-width: 992px) {
  .paragraph.width-content-wide > .paragraph-inner {
    max-width: 990px;
  }
}

@media screen and (min-width: 1200px) {
  .paragraph.width-content-wide > .paragraph-inner {
    max-width: 1200px;
  }
}

.paragraph.width-narrow-content > .paragraph-inner {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .paragraph.width-narrow-content > .paragraph-inner {
    max-width: 750px;
  }
}

.main--2-columns .paragraph .paragraph-inner,
.main--3-columns .paragraph .paragraph-inner {
  padding-left: 0;
  padding-right: 0;
}

.paragraph.pg-has-bg-media .paragraph-inner {
  position: relative;
}

.paragraph.pg-has-bg-media .paragraph-inner .pg-content-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 2;
}

.paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #FFF;
}

@media screen and (min-width: 768px) {
  .paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner {
    max-width: 750px;
  }
}

@media screen and (min-width: 992px) {
  .paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner {
    max-width: 990px;
  }
}

@media screen and (min-width: 1200px) {
  .paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner {
    max-width: 1200px;
  }
}

.paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner h1, .paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner h2, .paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner h3, .paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner h4, .paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner h5 {
  color: inherit;
}

.paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner h1, .paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner h2 {
  text-transform: uppercase;
  font-weight: 900;
}

.paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner h1 a, .paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner h2 a {
  text-decoration: none;
  color: 900;
}

.paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner h1 a:hover, .paragraph.pg-has-bg-media .paragraph-inner .pg-content-inner h2 a:hover {
  cursor: text;
}

.paragraph.pg--vm--featured .paragraph-inner {
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.paragraph.pg--vm--featured .icon-scroll {
  width: 20px;
  height: 35px;
  position: absolute;
  margin-left: -10px;
  left: 50%;
  bottom: 15px;
  display: none;
}

@media screen and (max-width: 767px) {
  .paragraph.pg--vm--featured {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .paragraph.pg--vm--featured .icon-scroll {
    display: block;
  }
}

@media screen and (min-width: 1500px) {
  .paragraph.pg--vm--featured {
    padding-left: 0;
    padding-right: 0;
  }
}
