@import "./_sass-essentials/essentials.scss";

.paragraph {
  margin-bottom: $co-mob;

  @include mq($bp-sm-min) {
    margin-bottom: $co-desk;
  }

  @include mq($bp-md-min) {
    margin-bottom: $co-lg;
  }

  @include mq($bp-lg-min) {
    margin-bottom: $co-xl;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .text-formatted:last-child {
    h2, h3, h4 {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.width-content-wide {
    > .paragraph-inner {
      @include default-boxed-content-width;
    }
  }

  &.width-narrow-content {
    > .paragraph-inner {
      @include default-narrow-content-width;
    }
  }

  .main--2-columns &,
  .main--3-columns & {
    .paragraph-inner {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.pg-has-bg-media {
    .paragraph-inner {
      position: relative;

      .pg-content-wrap {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        z-index: 2;
      }

      .pg-content-inner {
        @include default-boxed-content-width;
        text-align: left;
        padding-top: 15px;
        padding-bottom: 15px;

        color: $c-white;

        h1, h2, h3, h4, h5 {
          color: inherit;
        }

        h1, h2 {
          text-transform: uppercase;
          font-weight: $fw-black;

          a {
            text-decoration: none;
            color: $fw-black;

            &:hover {
              cursor: text;
            }
          }
        }
      }
    }
  }

  &.pg--vm--featured {
    .paragraph-inner {
      padding-left: 0;
      padding-right: 0;
      position: relative;
    }

    .icon-scroll {
      width: 20px;
      height: 35px;
      position: absolute;
      margin-left: -10px;
      left: 50%;
      bottom: 15px;
      display: none;
    }

    @include mq(false, $bp-mobile-max) {
      padding-left: 0;
      padding-right: 0;
    }

    @include mq($bp-sm-min) {
      .icon-scroll {
        display: block;
      }
    }

    @include mq($bp-xl-min) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
