//==============================================================================
// TYPOGRAPHY
//==============================================================================

// Families
$ff-condensed: 'Roboto', Helvetica, Arial, sans-serif;
$ff-normal: 'Roboto', Helvetica, Arial, sans-serif;
$ff-titles: 'Roboto', Helvetica, Arial, sans-serif;
$ff-menus: 'Roboto', Helvetica, Arial, sans-serif;
$ff-icons: 'FontAwesome';

// Font Weights
$fw-thin: 100;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-black: 900;

// Font Sizes
$base-fs-px: 18px;
$base-fs-rem: 1rem;
$large-fs-px: ceil(($base-fs-px * 1.15));
$small-fs-px: ceil(($base-fs-px * 0.85));

$fs-h1: 40px;
$fs-h2: 25px;
$fs-h3: $base-fs-px;
$fs-h4: $base-fs-px;
$fs-h5: $base-fs-px;
$fs-h6: ceil($base-fs-px * 0.85);

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.25;

// Other Sizes
$base-spacing: $base-line-height * 1rem;
$small-spacing: $base-spacing / 2;

// Buttons
$btn-fw: $fw-medium;
