//==============================================================================
// VARIABLES
//==============================================================================

//-----------------------------
// RWD Related
//-----------------------------

// Mobile toggle
$toggle-offset: 15px;
$toggle-width: 20px;
$toggle-height: 20px;
$toggle-lh: $toggle-height;
$toggle-bar-height: 3px;
$toggle-bar-radius: 0;
$toggle-bar1-3dpos: floor(floor($toggle-height / 2) - floor($toggle-bar-height / 2));
$toggle-bar2-pos: floor(floor($toggle-height / 3) + floor($toggle-bar-height / 2));
$toggle-bar3-pos: $toggle-bar2-pos * 2;
$toggle-bar3-3dpos: -($toggle-bar1-3dpos);
$toggle-v-padding: 10px;
$toggle-h-padding: 10px;
$mt-btn-bw: 2px;
$mt-btn-bs: solid;
$mt-btn-br: 0;
$mt-btn-height: floor($toggle-height + floor($toggle-v-padding * 2) + floor($mt-btn-bw * 2));

$toggle-wrapper-class: 'mobile-header';
$mobile-nav-class: 'main-nav-collapse';

// Grid system
$grid-cols: 12;

// Container max-widths
$cont-xs: 490px !default;
$cont-sm: 750px !default;
$cont-md: 990px !default;
$cont-lg: 1200px !default;
$cont-xl: 1470px !default;
$cont-featured: 1340px !default;

// Gutters
$l-gutter: 30px;
$l-gutter-half: floor($l-gutter / 2);
$l-gutter-neg: -($l-gutter-half);

$l-gut-xs: 20px;
$l-gut-sm: 30px;
$l-gut-md: 50px;
$l-gut-lg: 100px;
$l-gut-xl: 130px;

$l-half-gut-xs: floor($l-gut-xs / 2);
$l-half-gut-sm: floor($l-gut-sm / 2);
$l-half-gut-md: floor($l-gut-md / 2);
$l-half-gut-lg: floor($l-gut-lg / 2);
$l-half-gut-xl: floor($l-gut-xl / 2);


//-----------------------------
// Content Related
//-----------------------------

// Borders
$base-border-radius: 3px;
$base-border-width: 1px;
$base-border-style: solid;
$base-border-color: $c-base-border-color;

$thick-border-width: 2px;
$big-border-radius: 10px;

$base-border: $base-border-width $base-border-style $base-border-color;
$thick-border: $thick-border-width $base-border-style $base-border-color;

// Content offset
$co-mob: 20px;
$co-desk: 30px;
$co-lg: 40px;
$co-xl: 50px;

// Forms
$fi-fontsize: 0.8em;
$fi-minheight: 35px;
$fi-bg: $c-fi-bg;
$fi-bg-hov: darken($c-fi-bg, 5%);
$fi-border-width: 1px;
$fi-border-style: solid;
$fi-border-color: $c-fi-border-color;
$fi-border-color-hov: darken($c-fi-border-color, 15%);
$fi-border: $fi-border-width $fi-border-style $fi-border-color;
$fi-border-radius: 0;
$fi-lbl-color: $c-lbl-color;
$fi-lbl-bottom-spacing: 3px;

$fs-border: $fi-border-width $fi-border-style $c-fs-border-color;
$fs-legend-color: $c-lbl-color;

$btns-spacing: 5px;

$radio_width: 16px;
$radio_border_color: $c-brand-primary;
$radio_marker_color: $c-brand-primary;
$radio_border_width: 2px;
$radio_white_space: 2px;
$radio_top_offset: 5px;
$radio_base_bg: transparent;
$radio_inner_offset: $radio_border_width + $radio_white_space;
$radio_inner_width: floor($radio_width - ($radio_inner_offset * 2));
$radio_inner_top_offset: $radio_top_offset + $radio_inner_offset;
$radio_disabled_marker_color: $c-gray--dark;
$radio_disabled_base_bg: $c-gray--normal2;

$checkbox_width: 16px;
$checkbox_border_color: $c-brand-primary;
$checkbox_marker_color: $c-brand-primary;
$checkbox_border_width: 2px;
$checkbox_white_space: 1px;
$checkbox_top_offset: 5px;
$checkbox_base_bg: transparent;
$checkbox_inner_left_offset: $checkbox_border_width + $checkbox_white_space + 2px;
$checkbox_inner_top_offset: $checkbox_top_offset + 2px;
$checkbox_disabled_marker_color: $c-gray--dark;
$checkbox_disabled_base_bg: $c-gray--normal2;

// Buttons
$bw-btn-default: 1px;
$bs-btn-default: solid;

// Double Border Button Settings
$dbbs-primary: (
  def-bw: $bw-btn-default,
  def-olw: 2px,
  text-trans: uppercase,
  md-pad-top: 10px,
  md-pad-bot: 10px,
  md-bw: 2px,
  md-olw: 4px,
);

// Tables
$tbl-border-color: $c-gray--light;
$tbl-border: 1px solid $tbl-border-color;
$tbl-padding: 0.75rem 1rem;
$tbl-border-radius: 3px;
$tbl-line-height: 1.5rem;
$tbl-spacing: 1.5rem;
$tbl-head-bg-color: lighten($c-white, 10);
$tbl-foot-bg-color: $tbl-head-bg-color;
$tbl-row-hov-bg-color: darken($c-white, 2);

// Blockquotes
$bq-bg-color: #f9f9f9;
$bq-border-color: #ccc;
$bq-border-left: 10px solid $bq-border-color;
$bq-quotes-color: #ccc;
