//==============================================================================
// Media Queries (mq)
//==============================================================================

@mixin mq($mq-min: $bp-xs-min, $mq-max: false, $mq-type: screen) {
  $min: "";
  $max: "";

  @if $mq-min != false {
    $min: "min-width: #{$mq-min}";
  }

  @if $mq-max != false {
    @if $mq-min == false {
      $max: "max-width: #{$mq-max}";
    } @else {
      $max: ") and (max-width: #{$mq-max}";
    }
  }

  @media #{$mq-type} and (#{$min}#{$max}) {
    @content;
  }
}
