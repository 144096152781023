//==============================================================================
// COLOR VARIABLES
//==============================================================================

//-----------------------------
// CUSTOM COLORS
//-----------------------------




//-----------------------------
// FIXED COLORS
//-----------------------------

$c-brand-primary: #FFD400;
$c-brand-primary-dark: #D6AB0A;
$c-brand-secundary: #29363E;

$c-white: #FFF;
$c-black: #121012;

$c-gray--dark: #58585b;
$c-gray--normal: #8B8581;
$c-gray--normal2: #B3B3B3;
$c-gray--light: #EFEFEF;
$c-gray--light2: #F9F9F9;

$c-red: #C1272D;
$c-green: #8CC63F;


$c-status-light: #e6efc2;
$c-status: #049e19;
$c-notice-light: #e5edf8;
$c-notice: #0086c9;
$c-warning-light: #fff6bf;
$c-warning: #ee7f00;
$c-error-light: #fbe3e4;
$c-error: #b33630;


//-----------------------------
// COMPONENTS COLORS
//-----------------------------

// Mobile toggle
$c-mt-bar-bg: $c-brand-secundary;
$c-mt-bar-hov-bg: $c-brand-secundary;
$c-mt-bar-toggled-bg: $c-brand-secundary;
$c-mt-btn-bg: $c-white;
$c-mt-btn-hov-bg: $c-brand-primary;
$c-mt-btn-border: $c-brand-secundary;
$c-mt-btn-hov-border: $c-brand-primary;
$c-mt-span-txt: $c-brand-secundary;
$c-mt-span-hov-txt: $c-brand-secundary;

// Content colors
$c-base-txt: $c-brand-secundary;
$c-base-link: $c-brand-primary-dark;
$c-base-link-hov: $c-gray--dark;

$c-darkbg-txt: $c-white;
$c-darkbg-link: $c-brand-primary;
$c-darkbg-link-hov: $c-gray--normal2;

// Borders
$c-base-border-color: $c-brand-primary;

// Forms
$c-select-arrow: $c-brand-secundary;
$c-fi-bg: $c-white;
$c-fi-border-color: #d4d7d9;
$c-fi-border-focus: $c-brand-primary;
$c-description-txt: $c-gray--normal;
$c-fs-border-color: $c-brand-secundary;
$c-fs-bg: $c-gray--light2;
$c-lbl-color: #676d70;

// Tables
$c-base-table-border-color: $c-base-border-color;

// Shadows
$c-shadow: $c-black;
$c-shadow-rgba: rgba($c-shadow, 0.06);
$c-shadow-focus: adjust-color($c-shadow-rgba, $lightness: -5%, $alpha: -0.3);

// Button colors
$c-btn-default: (
  txt: $c-white,
  icon: $c-white,
  bg: $c-brand-primary,
  border: $c-brand-primary,
  txt-hov: $c-black,
  icon-hov: $c-black,
  bg-hov: $c-gray--light,
  border-hov: $c-gray--light,
);

$c-btn-primary: (
  txt: $c-brand-secundary,
  icon: $c-brand-secundary,
  bg: $c-brand-primary,
  border: $c-brand-primary,
  txt-hov: $c-white,
  icon-hov: $c-white,
  bg-hov: $c-gray--dark,
  border-hov: $c-gray--dark,
);

$c-btn-secundary: (
  txt: $c-brand-primary,
  icon: $c-brand-primary,
  bg: $c-brand-secundary,
  border: $c-brand-secundary,
  txt-hov: $c-black,
  icon-hov: $c-black,
  bg-hov: $c-gray--light,
  border-hov: $c-gray--light,
);

$c-btn-white: (
  txt: $c-brand-secundary,
  icon: $c-brand-secundary,
  bg: $c-white,
  border: $c-white,
  txt-hov: $c-white,
  icon-hov: $c-white,
  bg-hov: $c-brand-secundary,
  border-hov: $c-brand-secundary,
);

$c-btn-gray: (
  txt: $c-white,
  icon: $c-white,
  bg: $c-gray--normal2,
  border: $c-gray--normal2,
  txt-hov: $c-white,
  icon-hov: $c-white,
  bg-hov: $c-gray--dark,
  border-hov: $c-gray--dark,
);

// Double Border Button colors

$c-btn-db-primary: (
  txt: $c-brand-secundary,
  icon: $c-brand-secundary,
  bg: $c-brand-primary,
  border: $c-brand-secundary,
  outline: $c-brand-primary,
  txt-hov: $c-brand-primary,
  icon-hov: $c-brand-primary,
  bg-hov: $c-brand-secundary,
  border-hov: $c-brand-primary,
  outline-hov: $c-brand-secundary,
);

// Message colors
$c-msg-text: $c-gray--dark;
$c-msg-status-bg: $c-status-light;
$c-msg-status-border: $c-status;
$c-msg-notice-bg: $c-notice-light;
$c-msg-notice-border: $c-notice;
$c-msg-warning-bg: $c-warning-light;
$c-msg-warning-border: $c-warning;
$c-msg-error-bg: $c-error-light;
$c-msg-error-border: $c-error;

// Pager
$c-pager-btn-bg: transparent;
$c-pager-btn-txt: $c-brand-primary;
$c-pager-btn-bg-hov: $c-gray--light;
$c-pager-btn-txt-hov: $c-gray--dark;
$c-pager-btn-bg-active: $c-gray--dark;
$c-pager-btn-txt-active: $c-white;

// Horizontal tabs
$c-tabs-border: $c-base-border-color;
$c-tabs-btn-txt: $c-brand-primary;
$c-tabs-btn-border: $c-brand-primary;
$c-tabs-btn-txt-active: $c-brand-primary;
$c-tabs-btn-border-active: $c-brand-primary;

// Breadcrumbs
$c-bc-txt: $c-gray--normal2;

// Local tasks
$c-lt-menu-bg-desk: #f5f5f5;
$c-lt-trigger-bg: #e6e7e8;
$c-lt-trigger-txt-hov: $c-black;
$c-lt-trigger-bg-hov: #e6e7e8;
$c-lt-tab-bg: #e6e7e8;
$c-lt-tab-link: $c-base-txt;
$c-lt-prim-tab-bg: $c-gray--dark;
$c-lt-prim-tab-txt-active: $c-brand-primary;

$c-xs-tab-bg-hov: $c-gray--dark;
$c-xs-tab-txt-hov: $c-white;
$c-xs-active-tab-bg: $c-gray--light;
$c-xs-active-tab-txt: $c-brand-primary;

$c-sm-tab-bg-hov: $c-gray--dark;
$c-sm-tab-txt-hov: $c-white;

// Cookie Compliance Bar colors
$c-eucc-bg: $c-gray--dark;
$c-eucc-txt: $c-white;

$c-btn-eucc: (
  txt: $c-brand-primary,
  icon: $c-brand-primary,
  bg: transparent,
  border: $c-brand-primary,
  txt-hov: $c-white,
  icon-hov: $c-white,
  bg-hov: $c-brand-primary,
  border-hov: $c-brand-primary,
);

$c-btn-eucc-link: (
  txt: $c-white,
  icon: $c-white,
  bg: transparent,
  border: transparent,
  txt-hov: $c-brand-primary,
  icon-hov: $c-brand-primary,
  bg-hov: transparent,
  border-hov: transparent,
);

// System pages colors

$c-sys-page-bg: #F0F0F0;
$c-sys-wrap-bg: $c-white;
$c-sys-frm-head-bg: #F8F8F8;

// Maintenance cartoon colors
$c-sys-mpc: $c-brand-primary;
$c-sys-mpc-light: darken($c-brand-primary, 20%);
$c-sys-mpc-dark: lighten($c-brand-primary, 40%);
