//==============================================================================
// LAYOUT Mixins
//==============================================================================

//-----------------------------
// Gutters
//-----------------------------

@mixin l-gutter($gut-default: $l-gutter, $gut-sm: $l-gut-sm, $gut-md: $l-gut-md, $gut-lg: $l-gut-lg, $gut-xl: false) {
  padding-left: floor(($gut-default / 2));
  padding-right: ceil(($gut-default / 2));

  @if $gut-sm != false {
    @include mq($bp-sm-min) {
      padding-left: floor(($gut-sm / 2));
      padding-right: ceil(($gut-sm / 2));
    }
  }

  @if $gut-md != false {
    @include mq($bp-md-min) {
      padding-left: floor(($gut-md / 2));
      padding-right: ceil(($gut-md / 2));
    }
  }

  @if $gut-lg != false {
    @include mq($bp-lg-min) {
      padding-left: floor(($gut-lg / 2));
      padding-right: ceil(($gut-lg / 2));
    }
  }

  @if $gut-xl != false {
    @include mq($bp-xl-min) {
      padding-left: floor(($gut-xl / 2));
      padding-right: ceil(($gut-xl / 2));
    }
  }
}

@mixin l-gutter-antimargin($default: $l-gutter, $gut-xs: false, $gut-sm: false, $gut-md: false, $gut-lg: false, $gut-xl: false) {
  @if $default != false {
    margin-left: -(floor(($gut-xs / 2)));
    margin-right: -(ceil(($gut-xs / 2)));
  }

  @if $gut-xs != false {
    @include mq(false, $bp-xs-max) {
      margin-left: -(floor(($gut-xs / 2)));
      margin-right: -(ceil(($gut-xs / 2)));
    }
  }

  @if $gut-sm != false {
    @include mq($bp-sm-min, $bp-sm-max) {
      margin-left: -(floor(($gut-sm / 2)));
      margin-right: -(ceil(($gut-sm / 2)));
    }
  }

  @if $gut-md != false {
    @include mq($bp-md-min, $bp-md-max) {
      margin-left: -(floor(($gut-md / 2)));
      margin-right: -(ceil(($gut-md / 2)));
    }
  }

  @if $gut-lg != false {
    @include mq($bp-lg-min, $bp-lg-max) {
      margin-left: -(floor(($gut-lg / 2)));
      margin-right: -(ceil(($gut-lg / 2)));
    }
  }

  @if $gut-xl != false {
    @include mq($bp-xl-min) {
      margin-left: -(floor(($gut-xl / 2)));
      margin-right: -(ceil(($gut-xl / 2)));
    }
  }
}

//-----------------------------
// Containers
//-----------------------------

// Common styles for all containers
@mixin l-cont($g: $l-gutter) {
  margin-right: auto;
  margin-left: auto;
  @if $g != 0 {
    padding-left: floor(($g / 2));
    padding-right: ceil(($g / 2));
  }
}

// Containers width
@mixin l-cont-width($c-sm: $cont-sm, $c-md: $cont-md, $c-lg: $cont-lg, $c-xl: $cont-xl, $width-type: 'max-width') {
  width: 100%;

  @if $c-sm != false {
    @include mq($bp-sm-min) {
      #{$width-type}: $c-sm;
    }
  }

  @if $c-md != false {
    @include mq($bp-md-min) {
      #{$width-type}: $c-md;
    }
  }

  @if $c-lg != false {
    @include mq($bp-lg-min) {
      #{$width-type}: $c-lg;
    }
  }

  @if $c-xl != false {
    @include mq($bp-xl-min) {
      #{$width-type}: $c-xl;
    }
  }
}

//-----------------------------
// CSS Helpers
//-----------------------------

// Responsive property
@mixin resp-prop($prop, $default-xs-value, $sm-value:false, $md-value:false, $lg-value:false, $xl-value:false){
  #{$prop}: $default-xs-value;

  @if $sm-value != false {
    @include mq($bp-sm-min) {
      #{$prop}: $sm-value;
    }
  }

  @if $md-value != false {
    @include mq($bp-md-min) {
      #{$prop}: $md-value;
    }
  }

  @if $lg-value != false {
    @include mq($bp-lg-min) {
      #{$prop}: $lg-value;
    }
  }

  @if $xl-value != false {
    @include mq($bp-xl-min) {
      #{$prop}: $xl-value;
    }
  }
}

//-----------------------------
// Default boxed width content
//-----------------------------

@mixin default-boxed-content-width() {
  @include l-cont($g: 0);
  @include l-gutter($gut-default: $l-gut-sm, $gut-sm: false, $gut-md: false, $gut-lg: false);
  @include l-cont-width($c-xl: false);
}

//-----------------------------
// Default narrow width content
//-----------------------------

@mixin default-narrow-content-width() {
  @include l-cont($g: 0);
  @include l-gutter($gut-default: $l-gut-sm, $gut-sm: false, $gut-md: false, $gut-lg: false);
  @include l-cont-width($c-md: false, $c-lg: false, $c-xl: false);
}

//-----------------------------
// Featured boxed width content
//-----------------------------

@mixin featured-boxed-content-width() {
  @include l-cont($g: 0);
  @include l-gutter($gut-default: $l-gut-sm, $gut-sm: false, $gut-md: false, $gut-lg: false);
  @include l-cont-width($c-xl: $cont-featured);
}
